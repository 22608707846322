import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Fade } from "react-awesome-reveal";
import HelixIcon from "../components/helixIcon";
import WarpIcon from "../components/warpIcon";
import Hero from "../components/hero";

const ForLawyers = ({ data }) => {

  const heroContent = data.markdownRemark.frontmatter.hero;
  const html = data.markdownRemark.html

  return (
    <Layout classes="team">
      <Seo title={heroContent.title} description="Law In Distress for Lawyers" />
      <Hero heroContent={heroContent} />
      <section className="article-content">
        <HelixIcon />
        <WarpIcon />
        <div className="container">
          <Fade triggerOnce>
            <section dangerouslySetInnerHTML={{ __html: html }} />
          </Fade>
        </div>
      </section>
      <section className="form" id="form">
        <div className="container text-center">
          <Fade triggerOnce>
            <h2>Sign up</h2>
            <p>To sign up for an interview please use the form below</p>
          </Fade>
        </div>
        <div className="container">
          <Fade triggerOnce>
            <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="For Lawyers">
              <input type="hidden" aria-label="Bot Field" name="bot-field" />
              <input type="hidden" aria-label="Form Name" name="form-name" value="For Lawyers" />
              <div className="row">
                <div className="col col-one-third">
                  <div className="form-group">
                    <input type="text" name="name" placeholder="Name" required id="name" aria-label="Name" />
                  </div>
                </div>
                <div className="col col-one-third">
                  <div className="form-group">
                    <input type="email" name="email" placeholder="Email" required id="email" aria-label="Email" />
                  </div>
                </div>
                <div className="col col-one-third">
                  <div className="form-group">
                    <input type="text" name="phone" placeholder="Phone" id="phone" aria-label="Phone" />
                  </div>
                </div>
                <div className="col col-full">
                  <div className="form-group">
                    <textarea name="practice-areas" placeholder="Practice Area(s)" required id="practice-areas" aria-label="Practice Area(s)" />
                  </div>
                </div>
                <div className="col col-full">
                  <div className="form-group">
                    <label for="interview-time-preference">Interview time preference
                      <select name="interview-time-preference" id="interview-time-preference" aria-label="interview-time-preference">
                        <option value="none">Please select an option</option>
                        <option value="Flexible">Flexible</option>
                        <option value="Work Hours">During work hours</option>
                        <option value="After hours / evening">After hours / evening</option>
                        <option value="Weekends">Weekends</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className="col col-full">
                  <div className="form-group actions">
                    <button className="button" type="submit" aria-label="Submit Form">Send</button>
                  </div>
                </div>
              </div>
            </form>
          </Fade>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`{
  markdownRemark(frontmatter: {template_key: {eq: "for-lawyers"}}) {
    html
    frontmatter {
      title
      hero {
        small_title
        title
        caption
      }
    }
  }
}
`

export default ForLawyers
